import React, { useEffect } from 'react';
import {Button, Input, Form } from "antd"; //message, 
// import {useDispatch} from "react-redux"; //, useSelector
import {Link} from "react-router-dom"; //useHistory, 
import IntlMessages from "../../util/IntlMessages";
import UsePageTitle from "./../../components/PageTitle"
// import CircularProgress from "components/CircularProgress/index";
import { v4 as uuidv4 } from 'uuid';

const ForgetPassword =()=> {
    // const dispatch = useDispatch();
    // const {loader, alertMessage, showMessage, authUser}= useSelector(({auth}) => auth);
    // const history = useHistory();

    const onLoad = () => {

    };

    useEffect(() => {
        onLoad();
    });
    
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };
    
    const onFinish = values => {

    };
    
    return (
       <>
            <UsePageTitle pageTitleKey='forgetPasswordPage.pageTitle' />
            <div className="gx-app-forgetpassword-wrap background-default">
                <div className="gx-app-forgetpassword-container">
                    <div className="gx-app-forgetpassword-main-content">
                        <h3 className='H3_semi_bold no_m'><IntlMessages id="forgetPasswordPage.pageTitle"/></h3>
                        <p className="H4_semi_bold no_m m_t_1_25"><IntlMessages id="forgetPasswordPage.pageDescription"/></p>
                        
                        <div className="app-forgetpassword-form m_t_2">
                            <Form initialValues={{ remember: true }} layout="vertical" name="login" onFinish={onFinish} onFinishFailed={onFinishFailed} className="gx-signin-form gx-form-row0">
                                <Form.Item initialValue="blackcats.n@gmail.com" label={<IntlMessages id="forgetPasswordPage.email"/>} name="email">
                                    <Input placeholder={<IntlMessages id="forgetPasswordPage.email"/>}/>
                                </Form.Item>
                                <Form.Item>
                                    <Button key={uuidv4()} block className="gx-btn btn-forgetpassword-style no_m m_t_1_25" htmlType="submit">
                                        {<IntlMessages id="common.buttonConfirm"/>}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="app-goto-link">
                            <Link to="/signin" key={uuidv4()}  className="btn-goto-signin">{<IntlMessages id="forgetPasswordPage.backToSigninPage"/>}</Link>
                        </div>
                    
                    </div>
                </div>
            </div>
       </>
    );
};
export default ForgetPassword;