import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'; //, useHistory
import { Menu } from "antd";
import { v4 as uuidv4 } from 'uuid';
import IntlMessages from "../../util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import { useSelector } from "react-redux";

import {
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";

import { URL_MAPPER_UUID } from "./../../constants/UrlMapperUUID";

const SubMenu = Menu.SubMenu;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
    // const history = useHistory();
    // const dispatch = useDispatch();
    let { navStyle } = useSelector(({ settings }) => settings);
    let { pathname } = useSelector(({ common }) => common);

    const [selectedKeys, setSelectedKeys] = useState("");
    const [defaultOpenKeys, setDefaultOpenKeys] = useState("");
    // const [permission, setPermission] = useState([]);

    useEffect(() => {
        let selectedKeys = pathname.substr(1)
        let defaultOpenKeys = selectedKeys.split('/')[0];

        let urlMapperResult = URL_MAPPER_UUID[pathname]
        if (urlMapperResult !== undefined) {
            selectedKeys = urlMapperResult.selectedKeys;
            defaultOpenKeys = urlMapperResult.defaultOpenKeys;
        }


        // let accessToken = localStorage.getItem('route-optimization-token');
        // const { data } = (accessToken) ? parseJwt(accessToken) : { data : { permissionToken: "", authorizationTimeStamp: ""}};
        // const { permissionToken, authorizationTimeStamp } = data;
        // const permissionListStr = AesDecrypt(permissionToken, authorizationTimeStamp);
        // let permission = [];
        // if (permissionListStr !== "") {
        //     permission = _.pluck(JSON.parse(permissionListStr), 'code');
        // }

        // if (permission.length <= 0 && permissionListStr === "") {
        //     localStorage.removeItem('route-optimization-token');
        //     dispatch(userSignOut())
        //     window.location.reload();
        // }

        setSelectedKeys(selectedKeys);
        setDefaultOpenKeys(defaultOpenKeys);
        // setPermission(permission);
    }, [pathname]);

    const getNavStyleSubMenuClass = (navStyle) => {
        if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
            return "gx-no-header-submenu-popup";
        }
        return "";
    };

    return (
        <>
            <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
            {
                (selectedKeys !== "") && <div className="gx-sidebar-content p_t_2">
                    <CustomScrollbars className="gx-layout-sider-scrollbar">
                        <Menu
                            defaultOpenKeys={[defaultOpenKeys]}
                            selectedKeys={[selectedKeys]}
                            theme={'light'}
                            selectable={true}
                            mode="inline">


                            <Menu.Item key="9eb83465-6f80-4749-a2b9-679f69cf4b23">
                                <Link key={uuidv4()} to="/optimizer"><i className="icon icon-map-street-view" />
                                    <span><IntlMessages id="menu.optimizerManagement" /></span>
                                </Link>
                            </Menu.Item>



                            <SubMenu key="ca44e605-115e-4469-95e6-3bb7e6de8c81" popupClassName={getNavStyleSubMenuClass(navStyle)} title={
                                <span>
                                    <i className="icon icon-setting" />
                                    <span><IntlMessages id="menu.masterConfigurationManagement" /></span>
                                </span>
                            }>
                                <Menu.Item key="abe5c077-03bc-4639-a392-6dc7781db641">
                                    <Link key={uuidv4()} to="/master-data/location">
                                        <span><IntlMessages id="menu.locationDataManagement" /></span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="ea03c3f1-81d1-457e-b40b-862da91d85e4">
                                    <Link key={uuidv4()} to="/master-data/truck">
                                        <span><IntlMessages id="menu.truckDataManagement" /></span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="4e885d5e-7339-4cde-83f4-7d637d674df3">
                                    <Link key={uuidv4()} to="/master-data/route">
                                        <span><IntlMessages id="menu.routeMasterManagement" /></span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="5bc3e48a-cfe4-41e5-9186-2c0397c31b79">
                                    <Link key={uuidv4()} to="/authorization/user-management">
                                        <span><IntlMessages id="menu.authorizationUserManagement" /></span>
                                    </Link>
                                </Menu.Item>

                            </SubMenu>

                            {/*

                            <SubMenu key="d3533b69-439d-4b89-9cf8-44d6e843c5f0" popupClassName={getNavStyleSubMenuClass(navStyle)} title={
                                <span>
                                    <i className="icon icon-family"/>
                                    <span><IntlMessages id="menu.groupEmployeeManagement" /></span>
                                </span>
                            }>
                                <Menu.Item key="dc8a212b-7e40-4e6e-af4c-96caf28b7815">
                                    <Link to="/employees/roles"><i className="icon icon-team"/>
                                        <span><IntlMessages id="menu.roleManagement" /></span>
                                    </Link>
                                </Menu.Item>

                                <Menu.Item key="826855c3-471f-46ab-acef-b621b629cd61">
                                    <Link to="/employees/users"><i className="icon icon-user-o"/>
                                        <span><IntlMessages id="menu.employeeManagement" /></span>
                                    </Link>
                                </Menu.Item>

                            </SubMenu> */}


                        </Menu>
                    </CustomScrollbars>
                </div>
            }


        </>
    )
};

SidebarContent.propTypes = {};
export default SidebarContent;
