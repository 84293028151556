//eslint-disable-next-line react-hooks/exhaustive-deps
import React, { useEffect, useState } from 'react';
import { Layout, Popover, Avatar, message } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { VerifyJwt } from 'util/Helpers';
import AppLocale from "lngProvider";
import CustomScrollbars from "util/CustomScrollbars";
import Auxiliary from "util/Auxiliary";

import {toggleCollapsedSideNav} from "../../appRedux/actions";
import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import { userSignOut, hideMessage, showAuthMessage } from "../../appRedux/actions/Auth";
import CircularProgress from "components/CircularProgress/index";
import { msalInstance } from "./../../Idp/AzureAD/instanceAzureAD";

const {Header} = Layout;

const UserInfo = () => {
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  //"eyJjb3JyZWxhdGlvbklkIjoiNTRjOGQwYzEtYjQ3Ny00MTQ4LWFlYjYtZGQzMmYwYmVmZDMzIiwic2NvcGVzIjpbInVzZXIucmVhZCJdLCJuYW1lIjoiUm91dGUgT3B0aW1pemF0aW9uIEF1dGhvcml6YXRpb24iLCJhdXRob3JpdHkiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vZDY1OTdkM2MtYzYwYi00OWI1LWE5YjEtYzQ3MGNmZWYzZjU2IiwiYXV0aGVudGljYXRpb25TY2hlbWUiOiJCZWFyZXIiLCJyZWRpcmVjdFVyaSI6Imh0dHA6Ly9sb2NhbGhvc3Q6MzAwMC9hdXRoIiwic3RhdGUiOiJleUpwWkNJNkltRmlZakZpT1RnMkxUYzVOV1l0TkRZNE9DMWhPRFE0TFRnMk1UWTVOR1V5TldJek9DSXNJbTFsZEdFaU9uc2lhVzUwWlhKaFkzUnBiMjVVZVhCbElqb2ljbVZrYVhKbFkzUWlmWDA9Iiwibm9uY2UiOiJiYWZhYjhmMC01ZDk1LTRhYTEtOTZjZi1mZWVjNmZkOTllZmMiLCJyZXNwb25zZU1vZGUiOiJmcmFnbWVudCIsImNvZGUiOiIiLCJjb2RlVmVyaWZpZXIiOiJLX19yUVR5Rm4tUjg0N1pIWkJQWXdhQWxuSHhJVWNaa0NIejQwQ2dsY2ZZIn0="

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
      <Avatar src={"https://via.placeholder.com/150"} className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  )
}

const Topbar = () => {
  
  const history = useHistory();
  const {locale, navStyle} = useSelector(({settings}) => settings);
  const navCollapsed = useSelector(({common}) => common.navCollapsed);
  const width = useSelector(({common}) => common.width);
  const {loader, alertMessage, showMessage}= useSelector(({auth}) => auth);
  const dispatch = useDispatch();

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const currentAppLocale = AppLocale[locale.locale];

  const [popoverEvent, setPopoverEvent] = useState(false);
  const [displayName, setDisplayName] = useState('');

  const onHandleEventLogOut = () => {
    dispatch(userSignOut());
    msalInstance.logoutRedirect()
  }

  useEffect(() => {
    let accessToken = localStorage.getItem('route-optimization-token');
    if (accessToken !== null && accessToken !== undefined && accessToken !== "") {
      let adProfile = msalInstance.getActiveAccount();
      if (adProfile) {
        setDisplayName((adProfile?.name !== "")? adProfile?.name : "");
      } else {
        localStorage.removeItem("route-optimization-token")
        history.push('/signin');
      }
    } else {
      localStorage.removeItem("route-optimization-token")
      dispatch(showAuthMessage(currentAppLocale.messages["errorMessage.accessTokenInValid"]));
      history.push('/signin');
    }
  }, [dispatch, currentAppLocale, history]);


  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  });

  const onChangePopoverEvent =  (status) => {
    setPopoverEvent(status);
  };

  const popoverElementPanel = () => (
    <CustomScrollbars className="gx-popover-lang-scroll customize-account-topbar">
      <ul className="gx-sub-popover">
        <li className="gx-media gx-pointer" key={'key-logout'} onClick={() => onHandleEventLogOut()}>
          <span className="gx-language-text">{'ออกจากระบบ'}</span>
        </li>
      </ul>
    </CustomScrollbars>);

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               dispatch(toggleCollapsedSideNav(!navCollapsed));
             }}
          />
        </div> : null}


      <ul className="gx-header-notifications gx-ml-auto">
        {
          width >= TAB_SIZE &&  
          <li className="gx-layout-user-display">
            <Popover overlayClassName="gx-popover-horizantal gx-popover-customize-account-topbar" onVisibleChange={() => onChangePopoverEvent(!popoverEvent)} placement="bottomRight" content={popoverElementPanel()} trigger="click">
                <div className="gx-pointer gx-user-display m_">
                  <UserOutlined className="overlay-icon-user-display" />
                  <span className="m_l_0_5 gx-user-info body_reg">{displayName}</span>
                  <i className={(!popoverEvent)? 'icon icon-chevron-down m_l_2': 'icon icon-chevron-up m_l_2'}/>
                </div>
            </Popover>
          </li>
        }
       

        { 
          width < TAB_SIZE &&  <Auxiliary><li className="gx-user-nav"><UserInfo/></li></Auxiliary>
        }
      </ul>

      {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
    </Header>
  );
};

export default Topbar;