
import { msalConfig } from "./authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
 export const loginRequest = {
    // scopes: ["openid", "profile","email","User.Read"],
    scopes: ["user.read"],
    name: "Route Optimization Authorization"
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */
export const silentRequest = {
    scopes: ["openid", "profile","email","User.Read"],
    loginHint: "example@domain.net"
};


export const msalInstance = new PublicClientApplication(msalConfig);