import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Drawer, Layout} from "antd";

import SidebarContent from "./SidebarContent";
import {toggleCollapsedSideNav, updateWindowWidth} from "appRedux/actions/Setting";
import {
    NAV_STYLE_FIXED,
    NAV_STYLE_DRAWER,
    NAV_STYLE_MINI_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    TAB_SIZE
  } from "../../constants/ThemeSetting";
  import { v4 as uuidv4 } from 'uuid';

const {Sider} = Layout;

const Sidebar = () => {
    const dispatch = useDispatch();
    let [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    const {navStyle} = useSelector(({settings}) => settings);
    const {navCollapsed, width} = useSelector(({common}) => common);


    const onToggleCollapsedNav = () => {
      dispatch(toggleCollapsedSideNav(!navCollapsed));
    };

    useEffect(()=>{
      setSidebarCollapsed(navStyle===NAV_STYLE_MINI_SIDEBAR)
    },[navStyle])

    useEffect(() => {
      window.addEventListener('resize', () => {
        dispatch(updateWindowWidth(window.innerWidth));
      })
    }, [dispatch]);

    let drawerStyle = "";

    if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) {
        drawerStyle = "gx-collapsed-sidebar"
    }

    return (
        <Sider
          className={`gx-app-sidebar ${drawerStyle}`}
          trigger={null}
          collapsed={(width < TAB_SIZE ? false : sidebarCollapsed || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
          theme={"dark"}
          key={uuidv4()}
          collapsible>
            {
              // MOBILE : DECKTOP
              (navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE) &&
                <Drawer
                  className={`gx-drawer-sidebar gx-drawer-sidebar-dark`}
                  placement="left"
                  closable={false}
                  onClose={onToggleCollapsedNav}
                  open={navCollapsed}>
                  <SidebarContent key={uuidv4()} sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
                </Drawer>

            }

            {
              !((navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE)) && <SidebarContent key={uuidv4()} sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
            }
        </Sider>)
}

export default Sidebar;
