export const TAB_SIZE = 992;

export const LAYOUT_TYPE_FULL = 'LAYOUT_TYPE_FULL';
export const THEME_TYPE_SEMI_DARK = 'THEME_TYPE_SEMI_DARK';

export const THEME_COLOR = 'THEME_COLOR';
export const THEME_TYPE = 'THEME_TYPE';
export const NAV_STYLE = 'NAV_STYLE';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';

export const NAV_STYLE_FIXED = 'NAV_STYLE_FIXED';
export const NAV_STYLE_MINI_SIDEBAR = 'NAV_STYLE_MINI_SIDEBAR';
export const NAV_STYLE_DRAWER = 'NAV_STYLE_DRAWER';
export const NAV_STYLE_NO_HEADER_MINI_SIDEBAR = 'NAV_STYLE_NO_HEADER_MINI_SIDEBAR';
export const NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR = 'NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR';