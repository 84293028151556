import React, { useEffect } from 'react';
import {Button, Avatar, message } from "antd"; //message, 
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import UsePageTitle from "./../../components/PageTitle"

import {
    hideMessage
} from "../../appRedux/actions";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./../../Idp/AzureAD/instanceAzureAD";

import CircularProgress from "components/CircularProgress/index";

const SignIn =()=> {
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const {loader, alertMessage, showMessage, authUser}= useSelector(({auth}) => auth);
    const history = useHistory();

    useEffect(() => {
        if (showMessage) {
          setTimeout(() => {
            dispatch(hideMessage());
          }, 100);
        }
        if (authUser !== null && authUser !== undefined) {
          history.push('/');
        }
    });

    const onHandleEventCJAuthorizedAccount = () => {
        instance.loginRedirect(loginRequest).catch((e) => {
        })
    }

    return (
        <>
            <UsePageTitle pageTitleKey='signinPage.pageTitle' />

            <div className="gx-app-login-wrap background-default">
                <div className="gx-app-login-container">
                    <div className="gx-app-login-main-content">
                        <div className="app-branding">
                            <img className="branding-logo" src={"./../../assets/images/main/logo.png"} alt={"Auction Platfrom Logo"}></img>
                            <h3 className='H3_semi_bold no_m m_l_1'><IntlMessages id="common.brandName"/></h3>
                        </div>
                        <div className="app-login-form m_t_2">
                            <Button block className="gx-btn btn-login-style no_m m_t_1_25" onClick={() => onHandleEventCJAuthorizedAccount()}>
                                <Avatar shape="circle" className="gx-size-30 m_r_0_5" src={"/assets/images/logoCJ.svg"} />
                                เข้าสู่ระบบด้วย CJ Account
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
        </>
    );
}

export default SignIn;
