import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";


const OptimizerModule = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/index`} />
    <Route path={`${match.url}/index`} component={asyncComponent(() => import('./index'))} />
    <Route path={`${match.url}/create`} component={asyncComponent(() => import('./create'))} />
    <Route path={`${match.url}/edit`} component={asyncComponent(() => import('./edit'))} />
    <Route path={`${match.url}/info`} component={asyncComponent(() => import('./detail'))} />
  </Switch>
);
export default OptimizerModule;