import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"; //
import { withRouter, useHistory } from "react-router-dom";
import { message } from "antd";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { msalInstance, loginRequest } from "../../Idp/AzureAD/instanceAzureAD";

import CircularProgress from "components/CircularProgress/index";

import {
    hideMessage,
    showAuthLoader,
    userSignInWithAzureAD,
} from "../../appRedux/actions";

const Authentication =()=> {
    const {instance, inProgress, accounts} = useMsal();
    const dispatch = useDispatch();
    const {loader, alertMessage, showMessage, authUser}= useSelector(({auth}) => auth);
    const history = useHistory();

    useEffect(() => {
        if (showMessage) {
          setTimeout(() => {
            dispatch(hideMessage());
            history.push('/');
          }, 100);
        }
        if (authUser !== null && authUser !== undefined) {
          history.push('/');
        }
    });

    useEffect(() => {
        if (accounts?.length === 0 || inProgress === InteractionStatus.None) {
            let azureProfile = msalInstance.getAllAccounts()[0];
            if (azureProfile) {
                msalInstance.setActiveAccount(azureProfile)
                loginRequest.name = azureProfile?.name;
            }
        
            instance.acquireTokenSilent(loginRequest).then((accessTokenResponse) => {
                dispatch(showAuthLoader());
                dispatch(userSignInWithAzureAD(accessTokenResponse))
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(loginRequest);
                }
                console.log(error);
            });
        }
    }, [history, instance, accounts, inProgress, dispatch]);

    return (
        <>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
        </>
    )
}
export default withRouter(Authentication);