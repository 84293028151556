import React from "react";
import { Layout } from "antd";
import App from "routes/index";
import {useSelector} from "react-redux";
import {useRouteMatch} from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";

import IntlMessages from "./../../util/IntlMessages";
import Sidebar from "../Sidebar/index";

import Topbar from "../Topbar/index";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";

const {Content, Footer} = Layout;

const MainApp = () => {
  const {navStyle} = useSelector(({settings}) => settings);
  const match = useRouteMatch();

  const getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_FIXED :
        return <Topbar/>;
      case NAV_STYLE_DRAWER :
        return <Topbar/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Topbar/>;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <NoHeaderNotification/>;
      default :
        return null;
    }
  };

  return (
      <Layout className="gx-app-layout">
        <Sidebar/>
        <Layout>
          {getNavStyles(navStyle)}
          <Content className={`gx-layout-content`}>
            <App match={match}/>
            <Footer>
              <div className="gx-layout-footer-content">
                <IntlMessages id="common.footerDescription" /> {
                  (process.env.REACT_APP_STATE_ENV !== "prod") && <>
                    , Build Version : {(process.env.REACT_APP_BUILD_VERSION)? process.env.REACT_APP_BUILD_VERSION : "dev"}
                    , App Version : {(process.env.REACT_APP_VERSION)? process.env.REACT_APP_VERSION : "dev"}
                  </>
                }
              </div>
            </Footer>
          </Content>
        </Layout>
      </Layout>
  )
};

export default MainApp;