import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import axios from 'axios';
import {
    SIGNIN_USER,
    SIGNIN_WITH_AZUREAD,
    SIGNOUT_USER
} from "constants/ActionTypes";

import {showAuthMessage, userSignInSuccess, userSignOutSuccess} from "./../actions/Auth";
import { AUTHORIZATION_AZURE_ACCESSTOKEN } from "./../../services/ServiceEndpoint";

const signInUserWithEmailPasswordRequest = async (email, password) => {
  return {
    accessToken: "TEST"
  }
}


function* signInUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    try {
      const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
      if (signInUser && signInUser.error && signInUser.error.message) {
        yield put(showAuthMessage(signInUser.error.message));
      } else if (signInUser && signInUser.accessToken) {
        localStorage.setItem('token', signInUser.accessToken);
        yield put(userSignInSuccess(signInUser.accessToken));
      } else {
        yield put(showAuthMessage("มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง"));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
      localStorage.removeItem('route-optimization-token');
      yield put(userSignOutSuccess(null));
    } catch (error) {
      yield put(showAuthMessage(error));
    }
}

const signInUserWithAzureADRequest = async (accessToken) => {
  let payload = {
    accessToken: accessToken,
  }

  let response = await axios.post(AUTHORIZATION_AZURE_ACCESSTOKEN, payload)
  .then(response => response.data)
  .catch(error => error.response);
  return response;
}

function* signInUserWithAzureAD({payload}) {
  const { accessToken } = payload;
  try {
    if (accessToken) {
      const signInUser = yield call(signInUserWithAzureADRequest, accessToken);
      if (signInUser && signInUser.error_code && signInUser.error_message) {
        yield put(showAuthMessage(signInUser.error_message));
      } else if (signInUser && signInUser.accessToken) {
        localStorage.setItem('route-optimization-token', signInUser.accessToken);
        yield put(userSignInSuccess(signInUser.accessToken));
      } else {
        yield put(showAuthMessage("มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง"));
      }
    } else {
      yield put(showAuthMessage("มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง"));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signInWithAzureAD() {
  yield takeEvery(SIGNIN_WITH_AZUREAD, signInUserWithAzureAD);
}

  
export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([fork(signInUser),
      fork(signInWithAzureAD),
      fork(signOutUser)]);
}
