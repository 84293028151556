import React from "react";
import ReactDOM from "react-dom";
import NextApp from './NextApp';
import * as serviceWorker from './registerServiceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

axios.interceptors.request.use(request => {
    const accessToken = localStorage.getItem('route-optimization-token');
    if (accessToken) {
        request.headers["Trace-Id"] = uuidv4();
        request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
}, error => {
    return error;
});


axios.interceptors.response.use(response => {
    return response;
}, async error => {
    if (error?.response?.status === 403) {
        localStorage.clear();
        window.location.href = '/';
    }
    if (error?.response?.status === 401) {
        const accessToken = localStorage.getItem('route-optimization-token');
        if (accessToken) {
            localStorage.removeItem('route-optimization-token');
            window.location.href = '/';
        }
    }

    return Promise.reject(error);
});


ReactDOM.render(<NextApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

