import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import UserManagement from 'pages/UserManagement/routes';

const MasterData = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/user-management`} />
    <Route path={`${match.url}/user-management`} component={UserManagement} />
  </Switch>
);

export default MasterData;