import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/main.less";
import configureStore, { history } from './appRedux/store';
import App from "./containers/App/index";

import {
  MsalProvider
} from "@azure/msal-react";

import {msalInstance} from "./Idp/AzureAD/instanceAzureAD";

const store = configureStore(/* provide initial state if any */);

const NextApp = () =>
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App}/>
        </Switch>
      </ConnectedRouter>
    </Provider>
  </MsalProvider>;

export default NextApp;
