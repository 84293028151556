import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const OptimizerModule = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/index`} />
    <Route
      path={`${match.url}/index`}
      component={asyncComponent(() => import("./index"))}
    />
  </Switch>
);
export default OptimizerModule;
