import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";

const UsePageTitle = ({pageTitleKey})=> {
    //eslint-disable-next-line react-hooks/exhaustive-deps
    const intl = useIntl()
    useEffect(() => {
        document.title = intl.formatMessage({id: pageTitleKey});
    }, [pageTitleKey, intl]);

    return (<div></div>)
}
export default UsePageTitle;

UsePageTitle.defaultProps = {
    pageTitleKey: PropTypes.string.isRequired
};