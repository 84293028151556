import enLang from "./entries/en-US";
import thLang from "./entries/th_TH";

const AppLocale = {
  en: enLang,
  th: thLang
};

export default AppLocale;

