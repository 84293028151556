import React from "react";
import {Route, Switch} from "react-router-dom";

import OptimizerModule from './../pages/Optimizer/routes';
import MasterDataModule from './MasterData/index';
import AuthorizationModule from './Authorization/index';

import NotFound from './../pages/Errors/500/index';
 


const App = ({match}) => (
    
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}optimizer`} component={OptimizerModule}/>
      <Route path={`${match.url}master-data`} component={MasterDataModule}/>
      <Route path={`${match.url}authorization`} component={AuthorizationModule}/>
      <Route path={`${match.url}error`} component={NotFound} />
      <Route component={NotFound} />
    </Switch>
  </div>
);

export default App;


