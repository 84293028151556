export const URL_MAPPER_UUID = {
    "/dashboard": {
        selectedKeys: "205f2b86-899f-4464-ad8d-ab65d9d35c12",
        defaultOpenKeys: "205f2b86-899f-4464-ad8d-ab65d9d35c12",
    },
    "/branchs/index": {
        defaultOpenKeys: "55227bad-a2fc-4b71-97b2-d109b437a1a9",
        selectedKeys: "55227bad-a2fc-4b71-97b2-d109b437a1a9",
    },
    "/optimizer/index": {
        defaultOpenKeys: "9eb83465-6f80-4749-a2b9-679f69cf4b23",
        selectedKeys: "9eb83465-6f80-4749-a2b9-679f69cf4b23",
    },
    "/master-data/location/index": {
        defaultOpenKeys: "ca44e605-115e-4469-95e6-3bb7e6de8c81",
        selectedKeys: "abe5c077-03bc-4639-a392-6dc7781db641",
    },
    "/master-data/truck/index": {
        defaultOpenKeys: "ca44e605-115e-4469-95e6-3bb7e6de8c81",
        selectedKeys: "ea03c3f1-81d1-457e-b40b-862da91d85e4",
    },
    "/master-data/route/index": {
        defaultOpenKeys: "ca44e605-115e-4469-95e6-3bb7e6de8c81",
        selectedKeys: "4e885d5e-7339-4cde-83f4-7d637d674df3",
    },
    "/authorization/user-management/index": {
        defaultOpenKeys: "ca44e605-115e-4469-95e6-3bb7e6de8c81",
        selectedKeys: "5bc3e48a-cfe4-41e5-9186-2c0397c31b79",
    },
    "/authorization/user-management/create": {
        defaultOpenKeys: "ca44e605-115e-4469-95e6-3bb7e6de8c81",
        selectedKeys: "5bc3e48a-cfe4-41e5-9186-2c0397c31b79",
    },
    "/authorization/user-management/edit": {
        defaultOpenKeys: "ca44e605-115e-4469-95e6-3bb7e6de8c81",
        selectedKeys: "5bc3e48a-cfe4-41e5-9186-2c0397c31b79",
    },
    "/authorization/user-management/info": {
        defaultOpenKeys: "ca44e605-115e-4469-95e6-3bb7e6de8c81",
        selectedKeys: "5bc3e48a-cfe4-41e5-9186-2c0397c31b79",
    },



    "/error/": {
        defaultOpenKeys: "",
        selectedKeys: "",
    }
}
