import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  // NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { v4 as uuidv4 } from 'uuid';

const SidebarLogo = ({sidebarCollapsed, setSidebarCollapsed}) => {
  const {width} = useSelector(({settings}) => settings);
  
  // themeType
  let navStyle = useSelector(({settings}) => settings.navStyle);

  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">
      {/* {(navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) ? <div className="gx-linebar">
        <i
          className={`gx-icon-btn icon icon-${!sidebarCollapsed ? 'menu-unfold' : 'menu-fold'} gx-text-white}`}
          onClick={() => {
            setSidebarCollapsed(!sidebarCollapsed)
          }}
        />
      </div> : null} */}

      <Link to="/" key={uuidv4()} className="gx-site-logo">
        <div className="app-branding">
            <img className="branding-logo" src={"/assets/images/main/logo.png"} alt={"Auction Platfrom Logo"}></img>
            <h3 className='H3_semi_bold no_m m_l_1'><IntlMessages id="common.brandNameOnMainPage"/></h3>
        </div>
      </Link>
    </div>
  );
};

export default SidebarLogo;