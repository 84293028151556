import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import MasterDataLocation from 'pages/MasterDataLocation/routes';
import MasterDataTruck from 'pages/MasterDataTruck/routes';
import MasterDataRoute from 'pages/MasterDataRoute/routes';

const MasterData = ({ match }) => (
  <Switch>
    <Redirect exact from={`${match.url}/`} to={`${match.url}/location`} />
    <Route path={`${match.url}/location`} component={MasterDataLocation} />
    <Route path={`${match.url}/truck`} component={MasterDataTruck} />
    <Route path={`${match.url}/route`} component={MasterDataRoute} />
  </Switch>
);

export default MasterData;
