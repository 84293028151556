export const SIGNIN = `${process.env.REACT_APP_REST_API_ENDPOINT}/auth/login?userType=admin`;
export const SIGNOUT = `${process.env.REACT_APP_REST_API_ENDPOINT}/auth/logout?userType=admin`;

// ================== OPTIMIZATION DOCUMENT ==================
export const ROUTE_OPT_DOCUSED_UPLOAD = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-used/upload`;
export const ROUTE_OPT_DOCUSED_ITEMS = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-used/items`;
export const ROUTE_OPT_DOCUSED_ITEM_REMOVE = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-used/item-remove`;
export const ROUTE_OPT_DOCUSED_ITEM_CONDITION = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-used/item-condition`;
export const ROUTE_OPT_DOCUSED_OPTIMIZE_FAILURE = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-used/item-failure`;
export const ROUTE_OPT_DOCUSED_ITEM_DOWNLOAD = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-used/item-download`;
export const ROUTE_OPT_DOCUSED_ITEM_INFO = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-used/item-info`;



// ================== PROCESS OPTIMIZED ==================
export const ROUTE_OPT_DOCOPTIMIZED_ITEMS = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-optimized/items`;
export const ROUTE_OPT_DOCOPTIMIZED_ITEM_REMOVE = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-optimized/item-remove`;
export const ROUTE_OPT_DOCOPTIMIZED_ITEM_DOWNLOAD = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-optimized/item-download`;
export const ROUTE_OPT_DOCOPTIMIZED_ITEM_MAPVISUALIZE = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-optimized/item-map-visualize`;


// ================== MASTER DOCUMENT ==================
export const DOCMASTER_LOCATION_UPLOAD = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-master/location/upload`;
export const DOCMASTER_LOCATION_ITEMS = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-master/location/items`;
export const DOCMASTER_LOCATION_ITEM_DOWNLOAD = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-master/location/item-download`;

export const DOCMASTER_TRUCK_UPLOAD = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-master/truck/upload`;
export const DOCMASTER_TRUCK_ITEMS = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-master/truck/items`;
export const DOCMASTER_TRUCK_ITEM_DOWNLOAD = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-master/truck/item-download`;

export const DOCROUTE_MASTER_UPLOAD = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-master/route/upload`;
export const DOCROUTE_MASTER_ITEMS = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-master/route/items`;
export const DOCROUTE_MASTER_ITEM_DOWNLOAD = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document-master/route/item-download`;

export const DOC_DISTANCE_MATRIX_ITEM_DOWNLOAD = `${process.env.REACT_APP_REST_API_ENDPOINT}/route-optimizer/document/distance-matrix/item-download`;


// ================== USER MANAGEMENT  ==================
export const AUTHORIZATION_USER_CREATE = `${process.env.REACT_APP_REST_API_ENDPOINT}/authorization/user-management/create`;
export const AUTHORIZATION_USER_ITEMS = `${process.env.REACT_APP_REST_API_ENDPOINT}/authorization/user-management/items`;
export const AUTHORIZATION_USER_ITEM_INFO = `${process.env.REACT_APP_REST_API_ENDPOINT}/authorization/user-management/item-info`;
export const AUTHORIZATION_USER_ITEM_REMOVE = `${process.env.REACT_APP_REST_API_ENDPOINT}/authorization/user-management/item-remove`;
export const AUTHORIZATION_USER_ITEM_UPDATE = `${process.env.REACT_APP_REST_API_ENDPOINT}/authorization/user-management/item-update`;

export const AUTHORIZATION_AZURE_ACCESSTOKEN = `${process.env.REACT_APP_REST_API_ENDPOINT}/authorization/azure-accesstoken`;
