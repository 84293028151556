import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import { ConfigProvider } from 'antd';
import {IntlProvider} from "react-intl";
import AppLocale from "lngProvider";
import { VerifyJwt } from 'util/Helpers';
import MainApp from "./MainApp";
import ForgetPassword from "./../../pages/ForgetPassword/forgetpassword";
import SignIn from "../../pages/SignIn/signin";
import Authentication from "../../pages/SignIn/authentication";
import UnAuthorized from '../../pages/Errors/401/index';

import { setInitUrl, userSignInSuccess, showAuthMessage } from "../../appRedux/actions/Auth";

const RestrictedRoute = ({component: Component, location, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: location}
          }}
        />}
  />;

let styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);

const App = () => {
    const {locale} = useSelector(({settings}) => settings);

    const dispatch = useDispatch();
    const {authUser, initURL} = useSelector(({auth}) => auth);
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();

    const currentAppLocale = AppLocale[locale.locale];
    
    useEffect(() => {
        document.body.classList.add('full-layout');
    });

    useEffect(() => {
      window.process = {
        ...window.process,
      };
    }, []);

    useEffect(() => {
      if (locale)
        document.documentElement.lang = locale.locale;
    }, [locale]);

    useEffect(() => {
      if (initURL === '') {
        dispatch(setInitUrl(location.pathname));
      }
    }, [dispatch, initURL, location.pathname]);

    useEffect(() => {
      let accessToken = localStorage.getItem('route-optimization-token');
      
      if (authUser !== undefined) {

      }


      if (authUser === undefined && accessToken !== null && accessToken !== undefined && accessToken !== "") {
        let verifyResult = VerifyJwt(accessToken);
        if (verifyResult && verifyResult.success) {
          dispatch(userSignInSuccess(accessToken));
        } else {
          localStorage.removeItem("route-optimization-token")
          //eslint-disable-next-line react-hooks/exhaustive-deps
          dispatch(showAuthMessage(currentAppLocale.messages[verifyResult.errorLocaleId]));
          history.push('/signin');
        }
      } else {
        if (location.pathname === '/') {
          if ((authUser === null || authUser === undefined)) {
            history.push('/signin');
          } else if (initURL === '' || initURL === '/' || initURL === '/signin' || initURL === '/auth') {
            history.push(process.env.REACT_APP_MAIN_PAGE_NAME);
          } else {
            history.push(initURL);
          }
        } else if (location.pathname === '/auth') {
          if ((authUser !== null && authUser !== undefined)) {
            history.push(process.env.REACT_APP_MAIN_PAGE_NAME);
          }
        }
      }
    }, [authUser, initURL, location, history, dispatch, currentAppLocale]);

    return (
        <ConfigProvider locale={currentAppLocale.antd}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <Switch>
              <Route exact path='/signin' component={SignIn}/>
              <Route exact path='/auth' component={Authentication}/>
              <Route exact path='/forget-password' component={ForgetPassword}/>
              <Route path={`${match.url}unauthorized`} component={UnAuthorized} />
              <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp}/> 
            </Switch>
          </IntlProvider>
        </ConfigProvider>
    )
}

export default memo(App);

